<template>
    <div class="railwayDetails">
        <div class="Details_Top">
            <div class="title_1"><span>{{ orderDetail.orderType }}</span>&emsp;-&emsp;<span>订舱详情</span></div>
            <div class="title_2">
                <span v-if="orderDetail.status == 1">询价中</span>
                <span v-if="orderDetail.status == 2">待委托</span>
                <span v-if="orderDetail.status == 3">运输中</span>
                <span v-if="orderDetail.status == 4">已到港</span>
                <span v-if="orderDetail.status == 5">已完成</span>
                <div class="shuaxin" @click="handleRefresh">刷新状态</div>
            </div>
            <div class="changeClose" @click="handleClose">X</div>
        </div>
        <!-- <div class="Details_Title">
            <div class="adress"></div>
            <div class="datas"></div>
        </div> -->

        <div class="Details_Title">
            <div class="titleContent">
                <div class="address">
                    <p>{{ orderDetail.froms || orderDetail.departure }}</p>
                    <p><img src="../../assets/homePage/departureAndArrival.png" alt=""></p>
                    <p>{{ orderDetail.tos || orderDetail.destination }}</p>
                </div>
                <div class="dateS"><span>{{ times }}</span></div>
            </div>
        </div>
        <!-- <div class="stepS">
            <el-steps :active="active" finish-status="success" space="222px" align-center>
                <el-step title="提交订单"></el-step>
                <el-step title="商家接单"></el-step>
                <el-step title="确定舱位"></el-step>
                <el-step title="列车出发"></el-step>
                <el-step title="列车到达"></el-step>
                <el-step title="已完成"></el-step>
            </el-steps>
            <ul>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>
                <li>
                    <p> 2022-09-08</p>
                    <p> 11:57:52</p>
                </li>

            </ul>
        </div> -->
        <!-- TAB切换 -->
        <div class="tabsdetail">
            <div class="tabsdetail_Top">
                <ul>
                    <li v-for="(item, index) in tabsdetail_Top" :key="index"
                        :class="{ 'tabsdetailActive': tabTabs == index }" @click="handleTabs(index)">{{ item.name }}
                    </li>
                </ul>
            </div>
            <!-- 概述 -->
            <div class="tabs_1" v-show="tabTabs == 0">
                <div class="tabs_1Top">
                    <div class="tabs_1TL">
                        <div class="Tit"><span>订单信息</span></div>
                        <div class="tabs_Detail">
                            <ul>
                                <li>下单人</li>
                                <li>下单时间</li>
                                <li>订舱类型</li>
                                <li>船名</li>
                                <li>航次</li>
                            </ul>

                            <ul>
                                <li>{{ orderDetail.shipper || '-' }}</li>
                                <li>{{ times || '-' }}</li>
                                <li>{{ orderDetail.shippingSpace || '-' }}</li>
                                <li>{{ orderDetail.transName || '-' }}</li>
                                <li>{{ orderDetail.voyageNumber || '-' }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="tabs_1TR">
                        <ul>
                            <li>贸易方式</li>
                            <li>提单号</li>
                            <li>装箱号</li>
                            <li>铅封号</li>
                            <li>装箱时间</li>
                        </ul>
                        <ul>
                            <li>{{ exportCargoOrderList.cupboard || '-' }}</li>
                            <li>{{ orderDetail.billNo || '-' }}</li>
                            <li>{{ orderDetail.packingNo || '-' }}</li>
                            <li>{{ orderDetail.sealNo || '-' }}</li>
                            <li>{{ pickUpTimeVal || '-' }}</li>
                        </ul>
                    </div>
                </div>
                <div class="tabs_1Bot">
                    <!-- <div class="tabs_1TL">
                        <div class="Tit"><span>货物信息</span></div>
                        <div class="tabs_Detail">
                            <ul>
                                <li>
                                    <span>集装箱类型：{{ exportCargoOrderList.packingType }}</span>
                                    <span v-if="exportCargoOrderList.packingType == 'FCL'">，数量：{{
                                            exportCargoOrderList.cupboardNum
                                            ||
                                            '0'
                                    }}个</span>

                                </li>
                                <li v-for="(item, index) in exportCargoOrderList2" :key="index">
                                    <span>商品名称：{{ item.itemName }},件数：{{ item.pkg }},重量：{{ item.kgs }}Kg,体积：{{ item.cbm
                                    }}m³</span>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="tabs_1TR">
                        <ul>
                            <li>订单号</li>
                            <li>计划出发时间</li>
                            <li>实际车次</li>
                            <li>实际出发时间</li>
                            <!-- <li>提货指令</li> -->
                        </ul>
                        <ul>
                            <li>{{ orderDetail.orderNo }}</li>
                            <li>{{ orderDetail.orderDetail || '-' }}</li>
                            <li>{{ orderDetail.voyageNumber || '-' }}</li>
                            <li>{{ orderDetail.transTime || '-' }}</li>
                            <!-- <li>--</li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 货物信息 -->
            <div class="tabs_1" v-show="tabTabs == 1">
                <div class="Tit"><span>集装箱类型：{{ exportCargoOrderList.packingType }}</span><span
                        v-if="exportCargoOrderList.packingType == 'FCL'">，数量：{{
                                exportCargoOrderList.cupboardNum
                                ||
                                '0'
                        }}个</span></div>

                <div style="margin-top: 24px;">
                    <el-table :data="exportCargoOrderList2" style="width: 100%">
                        <el-table-column prop="itemName" label="商品名称" width="180" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="pkg" label="件数(pkg)" width="180" show-overflow-tooltip>

                        </el-table-column>
                        <el-table-column prop="kgs" label="毛重(kgs)" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="cbm" label="体积(cbm)" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="hsCode" label="HS.CODE" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- 订单信息 -->
            <div class="tabs_2" v-show="tabTabs == 2">
                <ul>
                    <li>
                        <p>发货方</p>
                        <span>{{ orderDetail.shipperComp }} ，{{ orderDetail.shipper }}，{{ orderDetail.shipperMobile
                        }}，{{
        orderDetail.shipperEmail
}}，{{ orderDetail.shipperAddr }}</span>
                    </li>
                    <li>
                        <p>收货方</p>
                        <span>{{ orderDetail.consigneeComp }}，{{ orderDetail.consignee }}，{{ orderDetail.consigneeMobile
                        }}，{{
        orderDetail.consigneeEmail
}}，{{ orderDetail.consigneeAddr }}</span>
                    </li>
                    <li>
                        <p>通知人</p>
                        <span v-if="orderDetail.notifyParty">{{ orderDetail.notifyComp }}，{{ orderDetail.notifyParty
                        }}，{{ orderDetail.notifyPartyMobile }}，{{
        orderDetail.notifyPartyEmail
}}，{{ orderDetail.notifyPartyAddr }}</span>
                        <span v-else>无</span>

                    </li>
                    <li>
                        <p>到门人</p>
                        <span v-if="orderDetail.finalName">{{ orderDetail.finalComp }}，{{ orderDetail.finalName }},{{
                                orderDetail.finalMobile
                        }},{{
        orderDetail.finalEmail
}},{{ orderDetail.finalAddr }}</span>
                        <span v-else>无</span>
                    </li>
                </ul>
            </div>
            <!-- 费用信息 -->
            <div class="tabs_3" v-show="tabTabs == 3">
                <!-- <p>订舱费</p>
                <span>订舱费：7600.00$</span> -->
                <el-table :data="exportCargoOrderFee" style="width: 100%">
                    <el-table-column prop="name" label="费用名称" width="180" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="费用" width="180" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <p> {{ scope.row.currency | getCurrency }} {{ scope.row.price }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="note" label="备注" width="200" show-overflow-tooltip>
                    </el-table-column>
                </el-table>

                <div class="changebill">
                    <button v-if="orderDetail.billStatus == 0">账单未生成</button>
                    <button @click="handleconfirmTheBill" v-if="orderDetail.billStatus == 1">确认账单</button>
                    <button v-if="orderDetail.billStatus == 2">账单已确认</button>
                    <button v-if="orderDetail.billStatus == 3">已开票</button>
                    <!-- <el-button type="primary" v-if="orderDetail.billStatus == 0">账单未生成</el-button>
                    <el-button type="primary" v-if="orderDetail.billStatus == 1">确认账单</el-button>
                    <el-button type="primary" v-if="orderDetail.billStatus == 2">账单已确认</el-button> -->
                </div>
            </div>
            <!-- 单据信息 -->
            <!-- <div class="tabs_4" v-show="tabTabs == 3">
                <span>暂无数据</span>
            </div> -->
            <!-- 提箱记录 -->
            <!-- <div class="tabs_5" v-show="tabTabs == 3">
                
                <p>装箱时间 :&emsp; {{ pickUpTimeVal || '-' }} </p>

            </div> -->
            <!--  还箱信息 -->
            <div class="tabs_6" v-show="tabTabs == 5">
                <!-- <el-table :data="returnInformation" style="width: 100%">
                    <el-table-column prop="packingNo" label="箱号" width="180">
                    </el-table-column>
                    <el-table-column prop="billNo" label="还箱单REF" width="180">
                    </el-table-column>
                    <el-table-column prop="address" label="还箱时间" width="180">
                    </el-table-column>
                </el-table> -->
                <p>还箱时间 :&emsp;{{ returnTimeVal || "-" }} </p>
            </div>
            <div class="tabs_6 tabs_1" v-show="tabTabs == 4">
                <div class="tabs_1Top">
                    <div class="tabs_1TL">
                        <div class="Tit"><span>装箱信息及司机信息</span></div>
                        <div class="tabs_Detail">
                            <ul>
                                <li>拖车公司</li>
                                <li>司机姓名</li>
                                <li>司机电话</li>
                                <li>拖车车牌</li>
                            </ul>

                            <ul>
                                <li>{{ orderDetail.cargoComp || '-' }}</li>
                                <li>{{ orderDetail.cargoDriver || '-' }}</li>
                                <li>{{ orderDetail.cargoMobile || '-' }}</li>
                                <li>{{ orderDetail.cargoPlate || '-' }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="tabs_1TR">
                        <!-- <ul>
                            <li>装箱时间:</li>
                            <li>
                                {{ pickUpTimeVal || '-' }}
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="tabs_1Btn">
                <!-- <div class="btn_1">签署合同</div> -->
                <!-- <div class="btn_2" @click="handledelete">撤单</div> -->
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
// RentBox
import { baseUrl } from '../../tools/baseUrl';
import Qs from 'qs';
export default {
    name: "RailwayDetails_Component",
    props: ['detailorderNo', 'detailId', 'handleClose1', 'handleShuaxin'],
    data() {
        return {
            active: 2,
            tabTabs: 0,
            tableData: [],
            tabsdetail_Top: [
                { name: "概述" },
                { name: "货物信息" },
                { name: "联系人信息" },
                { name: "费用信息" },

                // { name: "单据信息", id: 3 },
                // { name: "装箱时间", id: 4 },

                { name: "司机信息" },
                { name: "还箱信息" },
            ],
            orderDetail: "", //基本信息
            times: '',
            exportCargoOrderList: [], //货物信息
            exportCargoOrderList2: [],
            exportCargoOrderFee: [],//费用信息
            returnInformation: [], //还箱信息

            pickUpTimeVal: "", //装箱时间
            returnTimeVal: "", //还箱时间
        }
    },
    created() {
        // console.log(this.detailorderNo);
        var token = sessionStorage.getItem("token")
        axios.get(`${baseUrl}/inquiryOrder/detail`, {
            // 参数列表
            params: { orderNo: this.detailorderNo },
            //请求头配置  
            headers: { 'authorization': token }
        })
            .then(res => {

                this.exportCargoOrderList = res.data.result.exportCargoOrderList[0];
                this.exportCargoOrderList2 = res.data.result.exportCargoOrderList[0].exportCargoOrderGoodsList; //商品详情
                console.log(res.data.result);
                this.orderDetail = res.data.result;
                if (this.orderDetail.createTime) {
                    this.times = this.orderDetail.createTime.split(" ")[0];
                }

                this.exportCargoOrderFee = res.data.result.exportCargoOrderFee; //费用信息
                this.returnInformation.push({
                    packingNo: res.data.result.packingNo,  ////装箱号
                    billNo: res.data.result.billNo, //提单号
                    returnTime: res.data.result.returnTime,//还箱时间
                });
                if (res.data.result.pickUpTime) {
                    this.pickUpTimeVal = res.data.result.pickUpTime.split(" ")[0];//装箱时间
                }
                if (res.data.result.returnTime) {
                    this.returnTimeVal = res.data.result.returnTime.split(" ")[0] || ''; //装箱时间
                };



            })
            .catch(err => {
                console.error(err);
            })

    },
    methods: {
        handleTabs(index) {
            this.tabTabs = index
        },
        handledelete() { //撤单
            // console.log(this.detailId);
            var token = sessionStorage.getItem("token")
            axios.delete(`${baseUrl}/inquiry/deleteInquiry`, {
                //参数列表
                params: { id: this.detailId },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleRefresh() {
            this.handleShuaxin()
        },
        handleClose() {
            this.handleClose1()
        },
        handleconfirmTheBill() { //确认账单

            console.log("%%%%%", this.orderDetail.id);
            var token = sessionStorage.getItem("token");
            let ids = this.orderDetail.id;
            let data = Qs.stringify({ "id": `${ids}` });
            axios.post(`${baseUrl}/inquiryOrder/sendBill`, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'authorization': token }

            })
                .then(res => {
                    if (res.data.success) {
                        this.$message({
                            showClose: true,
                            message: '确认成功',
                            type: 'success'
                        });
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                })

        }
    },
}
</script>

<style lang="scss" scoped>
.tabsdetailActive {

    color: #1890FF !important;
    border-bottom: 2px solid #1890FF;
}

.railwayDetails {
    padding: 0 30px 92px;
    // height: 900px;

    .Details_Top {
        position: relative;
        // padding-left: 32px;
        // overflow: hidden;
        padding-bottom: 24px;
        border-bottom: 1px solid;

        .title_1 {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;

            span:nth-child(1) {

                color: #1890FF;
            }

            span:nth-child(2) {
                color: #999999;
                // margin-left: 37px;
            }
        }

        .title_2 {
            display: flex;
            margin-top: 39px;

            span {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
            }

            .shuaxin {
                width: 72px;
                height: 24px;
                background: #1890FF;
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 24px;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
                margin-left: 32px;
                cursor: pointer;
            }
        }

        .changeClose {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 24px;
            cursor: pointer;
        }
    }

    .Details_Title {
        margin-top: 24px;
        width: 100%;

        // margin: 53px auto 0;
        .titleContent {
            margin: 0 auto;
            width: 500px;

            .address {
                display: flex;

                justify-content: space-between;

                p:nth-child(1) {
                    font-size: 18px;
                    font-weight: bold;
                    color: #1890FF;
                }

                p:nth-child(2) {
                    width: 72px;
                    height: 9px;
                    margin-left: 54px;
                }

                p:nth-child(3) {
                    margin-left: 54px;
                    font-size: 18px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #1890FF;
                }
            }

            .dateS {
                text-align: center;
            }
        }


    }

    .stepS {
        max-width: 1462px;
        height: 120px;
        background: #F4F4F4;
        margin-top: 24px;
        padding: 0 65px;

        ::v-deep .el-step__line {
            display: none;
        }

        ::v-deep .el-step__title.is-success {
            color: #333333;
            font-size: 16px;
            font-weight: 400;
        }

        ::v-deep .el-step__head.is-success {
            border-color: #1890FF;
            color: #1890FF;
        }

        ::v-deep .el-step__head.is-process {
            color: #FFFFFF;
            border-color: #999999;


            .el-step__icon {
                background: #999999;
            }
        }

        ::v-deep .el-step__head.is-wait {
            color: #FFFFFF;
            border-color: #999999;

            .el-step__icon {
                background: #999999;
            }
        }

        ::v-deep .el-step__title.is-process {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
        }

        ::v-deep.el-step__title.is-wait {
            font-size: 16px;
            font-weight: 400;
            color: #999999
        }

        ul {
            width: 100%;
            margin-top: 12px;
            display: flex;
            justify-content: space-around;

            li {
                width: 222px;
                flex: 1;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .tabsdetail {
        position: relative;
        margin-top: 32px;

        .tabsdetail_Top {


            ul {
                display: flex;
                border-bottom: 1px solid;
                // padding-bottom: 16px;

                li {
                    margin-left: 40px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    padding-bottom: 16px;
                    cursor: pointer;
                }

                li:nth-child(1) {
                    margin-left: 0;
                }
            }
        }

        .tabs_1 {
            // position: relative;
            margin-top: 16px;

            .tabs_1Top {
                display: flex;
                padding-bottom: 24px;
                border-bottom: 1px solid #999999;

                .tabs_1TL {
                    flex: 1;


                    .Tit {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }

                    .tabs_Detail {
                        margin-top: 24px;
                        display: flex;
                        justify-content: space-between;
                        padding-right: 25px;
                        border-right: 1px solid #999999;


                        ul {
                            li {
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #333333;
                            }

                            li:nth-child(1) {
                                margin-top: 0;
                            }
                        }

                        ul:nth-child(2) {
                            li {
                                text-align: right;
                                margin-top: 16px;
                            }

                            li:nth-child(1) {
                                margin-top: 0;
                            }
                        }
                    }
                }

                .tabs_1TR {
                    flex: 1;
                    padding-left: 24px;
                    margin-top: 48px;
                    display: flex;
                    justify-content: space-between;

                    ul {
                        li {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }
                }
            }

            .tabs_1Bot {
                position: relative;
                display: flex;
                margin-top: 24px;
                border-bottom: 1px solid #999999;
                padding-bottom: 24px;

                .tabs_1TL {
                    flex: 1;

                    .Tit {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }

                    .tabs_Detail {
                        position: relative;
                        margin-top: 24px;
                        display: flex;
                        justify-content: space-between;
                        padding-right: 25px;
                        // border-right: 1px solid #999999;


                        ul {
                            li {
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #333333;
                            }

                            li:nth-child(1) {
                                margin-top: 0;
                            }
                        }

                        ul:nth-child(2) {
                            li {
                                text-align: right;
                            }
                        }
                    }

                    .tabs_Detail::before {
                        position: absolute;
                        right: 0;
                        content: '';
                        width: 1px;
                        height: 134px;
                        background-color: #999999;
                    }
                }

                .tabs_1TR {
                    display: flex;
                    justify-content: space-between;
                    flex: 1;
                    // padding-left: 24px;
                    // margin-top: 40px;

                    ul {
                        li {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                        }
                    }

                    ul:nth-child(2) {
                        li {
                            text-align: right;
                            margin-top: 16px;
                        }
                    }
                }
            }


        }

        .tabs_2 {
            margin-top: 24px;

            ul {
                li {
                    margin-top: 24px;
                    border-bottom: 1px solid #999999;
                    padding-bottom: 24px;

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }

                    span {
                        font-size: 18px;
                        font-weight: 400;
                        margin-top: 8px;
                        color: #999999;
                    }
                }
            }
        }

        .tabs_3 {
            margin-top: 24px;
            position: relative;

            p {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }

            span {
                display: block;
                font-size: 18px;
                font-weight: 400;
                color: #999999;
                margin-top: 8px;
            }

            .changebill {
                position: absolute;
                right: 70px;
                top: 63px;
                width: 80px;
                height: 24px;
                background: #1890FF;
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                border-radius: 4px 4px 4px 4px;
                overflow: hidden;

                button {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    line-height: 24px;
                    border: 0;
                    background: #1890FF;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }

                button:nth-child(1) {
                    background: #999999;
                }

                button:nth-child(3) {
                    background: #999999;
                }

                button:nth-child(4) {
                    background: #999999;
                }
            }

            .changebill::before {
                position: absolute;
                content: '';
                top: 0;
                left: -48px;
                width: 1px;
                height: 60px;
                background-color: #999999;
                transform: translateY(-25%);
            }
        }

        .tabs_4 {
            margin-top: 24px;
            line-height: 92px;
            font-size: 18px;
            font-weight: 400;
            color: #999999;
        }

        .tabs_5 {
            margin-top: 24px;

            p {
                font-size: 14px;
                margin-top: 10px;
            }
        }

        .tabs_6 {
            margin-top: 24px;
        }

        .tabs_1Btn {
            position: absolute;
            right: 32px;
            bottom: -50px;
            display: flex;

            .btn_1 {
                width: 120px;
                height: 40px;
                background: #4CAF50;
                box-shadow: 0px 3px 6px 1px rgba(76, 175, 80, 0.3);
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 40px;
            }

            .btn_2 {
                width: 120px;
                height: 40px;
                background: #D4252C;
                box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 40px;
                margin-left: 40px;
            }


        }
    }
}
</style>