<template>
    <div>
        <AirTransport_Compontent></AirTransport_Compontent>
    </div>
</template>

<script>
import AirTransport_Compontent from '../../components/PersonalCenter/AirTransport_Compontent.vue';
export default {
    name: "AirTransports",
    components: { AirTransport_Compontent }
}
</script>

<style>

</style>