<template>
    <!-- 空运订单 Railway RailwayDetails -->
    <div class="OceanShipping">
        <div v-if="isDetail">
            <div class="OceanShippingServer">
                <!-- 订单号 -->
                <div class="orderNumber">
                    <input type="text" placeholder="订单号" v-model="orderNumber" @input="inputNum1">
                </div>
                <div class="orderStatus">
                    <el-select v-model="departureStationValue" placeholder="出发站" v-el-select-loadmore="loadmore"
                        filterable :filter-method="remoteMethod" @focus="changeFous">
                        <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <div class="orderStatus">
                    <el-select v-model="destinationValue" placeholder="目的站" v-el-select-loadmore="loadmore1" filterable
                        :filter-method="remoteMethod1" @focus="changeFous1">
                        <el-option v-for="(item, index) in destinationListList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <!-- 订单状态 -->
                <div class="orderStatus">
                    <el-select v-model="inquiryOrderValue" placeholder="订单状态">
                        <el-option v-for="item in inquiryOrderList" :key="item.value" :label="item.value"
                            :value="item.states">
                        </el-option>
                    </el-select>
                </div>
                <!-- 下单范围日期 -->
                <div class="orderRangeDate">
                    <!-- <el-date-picker prefix-icon="" v-model="dateValue" type="date" placeholder="下单范围日期"
                        value-format="yyyy-MM-dd">
                    </el-date-picker> -->
                    <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                        :picker-options="pickerOptions" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <!-- 搜索按钮 -->
                <div class="server_Btn" @click=handleServer><i class="el-icon-search"></i><span>搜索</span></div>
            </div>

            <div class="tables">
                <el-table :data="orderDetailList" style="width: 100%" border :header-row-style="{ height: '50px' }"
                    :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }">
                    <el-table-column prop="orderNo" label="订单号" width="200">
                    </el-table-column>
                    <el-table-column prop="contractNo" label="合同号" width="200">
                    </el-table-column>
                    <el-table-column label="订单状态" width="150">
                        <template slot-scope="scope">
                            <p v-if="scope.row.status == 1">询价中</p>
                            <p v-if="scope.row.status == 2">待委托</p>
                            <p v-if="scope.row.status == 3">运输中</p>
                            <p v-if="scope.row.status == 4">已到港</p>
                            <p v-if="scope.row.status == 5">已完成</p>
                            <!-- <p v-if="scope.row.status == 0">询价中</p>
                            <p v-if="scope.row.status == 1">已报价</p>
                            <p v-if="scope.row.status == 2">已下单</p> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="支付状态" width="150">
                        <template slot-scope="scope">
                            <p v-if="scope.row.isPay == 0"> 未支付</p>
                            <p v-else> 已支付</p>

                        </template>
                    </el-table-column>
                    <el-table-column prop="froms" label="起点城市" width="150">
                    </el-table-column>
                    <el-table-column prop="tos" label="到达城市" width="150">
                    </el-table-column>
                    <el-table-column label="计划/车次" width="150">
                        <template slot-scope="scope">
                            <p> {{ scope.row.voyageNumber }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shippingSpace" label="舱位类型" width="150">
                    </el-table-column>
                    <!-- <el-table-column prop="cupboardNum" label="舱位" width="150">
                    </el-table-column> -->
                    <!-- <el-table-column prop="totalPrice" label="订单总价" width="150">
                        <template slot-scope="scope">
                            <span>{{ scope.row.currency | getCurrency }}</span>
                            <span>{{ scope.row.totalPrice }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="orderTime" label="下单时间" min-width="150">
                        <template slot-scope="scope">
                            <p>{{ scope.row.createTime.split(" ")[0] }}</p>
                        </template>
                    </el-table-column>


                    <el-table-column fixed="right" label="操作" width="200" class-name="nana">
                        <template slot-scope="scope">
                            <el-button @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>
                            <el-button @click="handleCancelTheOrder(scope.row)" type="text" size="small">撤单</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="paging">
                    <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                        :total="paging.total" :page-size="paging.size">
                    </el-pagination>
                </div>
            </div>

        </div>
        <RailwayDetails_Component :detailorderNo="detailorderNo" :detailId="detailId" :handleClose1="handleClose1"
            :handleShuaxin="handleShuaxin" v-else></RailwayDetails_Component>
    </div>
</template>
<!-- OceanShipping_Component.vue -->
<script>
import axios from 'axios';
import RailwayDetails_Component from './RailwayDetails_Component.vue';
import { baseUrl } from '../../tools/baseUrl';
import { homecityList, startCityList, endCityList } from '../../tools/ajax'
export default {
    name: "AirTransport_vCompontents1",
    components: { RailwayDetails_Component },
    data() {
        return {
            departureStationList: [], //出发站数据
            destinationListList: [], //目的站数据
            departureStationValue: "",  //出发站值
            destinationValue: "", //目的站值
            dateValue: "", //下单范围日期
            orderNumber: "", //订单号
            orderDetailList: [], //表格
            orderType: "",
            otherExpenses: "",//下单时间
            isDetail: true,
            inquiryOrderList: [{ //状态
                states: -2,
                value: '全部'
            }, {
                states: 1,
                value: '询价中'
            }, {
                states: 2,
                value: '待委托'
            }, {
                states: 3,
                value: '运输中'
            }, {
                states: 4,
                value: '已到港'
            }, {
                states: 5,
                value: '已完成'
            }],
            inquiryOrderValue: -2, //状态值
            detailorderNo: "", //详情订单号
            detailId: '',//订单id

            value1: "",
            begTime: '', //开始时间
            endTimes: '', //结束时间
            formData: {
                pageNo: 1, //当前页
            },
            formData1: {
                pageNo: 1, //当前页
            },
            paging: {
                total: 0,
                size: 5,
            },
            pickerOptions: {  // 对象
                // 禁用时间
                selectableRange: '10:00:00- 23:59:59', // 动态写法在初始化时 对象.的方式更改
                disabledDate: (time) => {  // 禁用日期
                    let nowData = new Date()
                    nowData = new Date(nowData.setDate(nowData.getDate() - 1))
                    return time < nowData
                }
            }
        }
    },
    created() {

        this.getstartCityList();//出发站
        this.getendCityList(); //目的站
        this.getinquiryOrder();

    },
    methods: {
        getstartCityList() { //出发站
            startCityList().then((res) => {
                this.departureStationList = res.result.records || '';
            })
        },
        getendCityList() { //目的站
            endCityList().then((res) => {
                console.log(res);
                this.destinationListList = res.result.records || '';
            })
        },
        inputNum1(e) {  //限制输入数字
            this.orderNumber = e.target.value;
            this.orderNumber = this.orderNumber.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.orderNumber = this.orderNumber.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.orderNumber = this.orderNumber.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.orderNumber = this.orderNumber.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },

        getinquiryOrder() {
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/inquiryOrder/list`, {
                params: { status: this.inquiryOrderValue, orderType: "空运订单" },
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result);
                this.orderDetailList = res.data.result.records
            });
        },


        changeFous() {
            this.formData.pageNo = 1;
            this.getstartCityList();
        },
        changeFous1() {
            this.formData1.pageNo = 1;
            this.getendCityList()
        },

        remoteMethod(value) { //
            // console.log("$$$", value);
            this.departureStationValue = value;
            if (value) {
                startCityList({ name: value }).then((res) => {
                    this.departureStationList = res.result.records || '';
                })
            } else {
                homecityList().then(res => {
                    // console.log(res.result);
                    this.departureStationList = res.result.records;
                    // this.destinationListList = res.result.records;
                });
            }

        },
        remoteMethod1(value) {
            this.destinationValue = value;
            if (value) {
                endCityList({ name: value }).then((res) => {
                    console.log(res);
                    this.destinationListList = res.result.records || '';
                })
            } else {
                homecityList().then(res => {
                    // console.log(res.result);
                    // this.departureStationList = res.result.records;
                    this.destinationListList = res.result.records
                });
            }
        },

        handleShuaxin() {
            this.isDetail = true
            this.$nextTick(() => {
                this.isDetail = false;
                console.log("$$$");
            })
        },
        handleClose1() {
            this.isDetail = true
        },

        handleServer() { //点击搜索
            // inquiryOrderValue
            var token = sessionStorage.getItem("token")
            if (this.value1) {
                this.begTime = this.value1[0];  //开始时间
                this.endTimes = this.value1[1]; //结束时间
            } else {
                this.begTime = '';  //开始时间
                this.endTimes = ''; //结束时间
            }
            axios.get(`${baseUrl}/inquiryOrder/list`, {
                params: {
                    orderType: "空运订单",
                    orderNo: this.orderNumber,
                    status: this.inquiryOrderValue,
                    departure: this.departureStationValue, //出发站
                    destination: this.destinationValue, //目的站
                    begTime: this.begTime,
                    endTime: this.endTimes
                },
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log(res)
                    this.orderDetailList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                })
                .catch(err => {
                    console.error(err);
                })
        },

        handleSizeChange(val) { //分页
            var token = sessionStorage.getItem("token");
            if (this.value1) {
                this.begTime = this.value1[0] || "";  //开始时间
                this.endTimes = this.value1[1] || ''; //结束时间
            } else {
                this.begTime = "";  //开始时间
                this.endTimes = ''; //结束时间
            }

            axios.get(`${baseUrl}/inquiryOrder/list`,
                {
                    //参数列表
                    params: {
                        orderType: "空运订单",
                        pageNo: val,
                        state: this.states,
                        departure: this.departureStationValue, //出发站
                        destination: this.destinationValue, //目的站
                        begTime: this.begTime || '',
                        endTime: this.endTimes || '',
                    },
                    //请求头配置  
                    headers: { 'authorization': token }
                }
            ).then(res => {
                // this.orderDetailList.push(res.data.result);
                // console.log("详情", res.data.result)
                this.orderDetailList = res.data.result.records
            }).catch(err => {
                console.error(err);
            })
        },

        handleOrderStatus(val) {
            this.orderType = val
        },
        handleOrderRangeDate(val) {
            this.otherExpenses = this.value1

        },
        handleDetail(row) {  //详情
            // console.log("@@", row.id);
            this.detailorderNo = row.orderNo;
            this.detailId = row.id
            this.isDetail = false;

        },
        handleCancelTheOrder(row) {  //撤单
            var token = sessionStorage.getItem("token")
            axios.delete(`${baseUrl}/inquiryOrder/revoke`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    console.log("撤销", res)
                    if (res.data.success == false) {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                        return false
                    } else if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '撤销成功',
                            type: 'success'
                        });
                        this.getinquiryOrder()
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },

        // #region //搜索框
        // 懒加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        loadmore1() {
            this.formData1.pageNo++;
            this.getList1(this.formData1);
        },
        // 下拉刷新
        getList(formData) {
            startCityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.departureStationList = [...this.departureStationList, ..._res];
                // this.destinationListList = [...this.destinationListList, ..._res];
            })
        },
        getList1(formData) {
            endCityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                // this.departureStationList = [...this.departureStationList, ..._res];
                this.destinationListList = [...this.destinationListList, ..._res];
            })
        }
        // #endregion
    }

}
</script>

<style lang="scss" scoped>
// ::v-deep .el-table tr:nth-child(2n-1) {
//     background: #F4F4F4;
// }

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

.OceanShipping {
    margin: 0 auto;
    // max-width: 1526px;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;

    .OceanShippingServer {
        display: flex;
        align-items: center;
        padding-left: 32px;
        height: 40px;

        .orderNumber {
            width: 180px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;

            input {
                width: 100%;
                height: 100%;
                padding-left: 16px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #C0C4CC;
                cursor: pointer;
            }

            input:focus {
                outline: 1px solid #409EFF;
            }
        }

        .orderStatus {
            width: 180px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;

            .el-select {
                width: 100%;
                height: 100%;
            }
        }

        .orderRangeDate {
            width: 284px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            // border: 1px solid #999999;
            margin-left: 32px;

            .el-date-editor {
                width: 100%;
                height: 100%;

            }

            ::v-deep .el-input__prefix {
                display: none;
            }
        }

        .server_Btn {
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            color: #2B2B2B;
            margin-left: 32px;
            cursor: pointer;
        }
    }

    .tables {
        width: 100%;
        margin-top: 60px;
        padding: 0 24px 32px;

        ::v-deep .nana {
            text-align: center;
        }

        ::v-deep .el-table_1_column_11 {
            text-align: center;
        }

        .el-button {
            // width: 72px;
            height: 24px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            // line-height: 24px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            padding: 6px 12px;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }


        ::v-deep .is-leaf {
            font-size: 14px;
            font-weight: 400;
            color: #707070;
        }

        ::v-deep .el-table__row {
            height: 84px;

            .el-table__cell {
                font-size: 14px;
                // font-weight: bold;
                color: #333333;

                .cell {
                    height: 24px;

                }
            }
        }


    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        // float: right;
        margin-top: 32px;
        // background-color: #FFFFFF;
    }
}
</style>